import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

import { useAuthContext } from "../../context/authProvider";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import validator from "../../resources/images/validator.png";
import donor from "../../resources/images/donor.png";

/**
 * Component for choosing login type.
 * This component provides functionality for users to choose between different types of login.
 * @returns {React.Element} - Returns JSX for choosing login type.
 */
const LoginType = () => {
  // Destructure the 'returnURL' object from the 'useAuthContext()' hook
  const { returnURL } = useAuthContext();

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column account">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={11} md={8} lg={6} xl={5} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4">
              <Card.Body>
                <h3 className="text-center mb-4 title">Sign in</h3>
                <Link
                  to={returnURL ? `/login?returnUrl=${returnURL}` : "/login"}
                  title="Sign in as a Validator or Staff"
                  className="d-flex align-items-center p-3 mb-4 border rounded text-decoration-none"
                >
                  <div className="flex-shrink-0">
                    <Image src={validator} alt="Validator" fluid />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="title">
                      Sign in as a Validator or <br /> Benevolent Staff
                    </h5>
                    <p className="mb-0 text-dark-emphasis fs-small">
                        For staff members of Benevolent and our partner organizations.
                    </p>
                  </div>
                </Link>
                <Link
                  to={
                    returnURL
                      ? `/donor/login?returnUrl=${returnURL}`
                      : "/donor/login"
                  }
                  title="Sign in as a Donor"
                  className="d-flex align-items-center p-3 border rounded text-decoration-none"
                >
                  <div className="flex-shrink-0">
                    <Image src={donor} alt="Validator" fluid />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="title">Sign in as a Donor</h5>
                    <p className="mb-0 text-dark-emphasis fs-small">
                        For those who wish to donate.
                    </p>
                  </div>
                </Link>
              </Card.Body>
            </Card>
            <div className="text-center mt-4">
              <p className="mb-0 text-dark-emphasis">
                Not a Member Yet?{" "}
                <Link
                  to="/register"
                  title="Register Now"
                  className="text-decoration-none link-secondary fw-semibold"
                >
                  Register Now
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default LoginType;
